import React from "react";

// let index = 0;
let id_of_setinterval = -1;

class TopSlider extends React.Component {
    state = {
        banners: [],
        index: 0
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleHeight);
        this.handleHeight();
        this.autoBanner();
        this.getBanner();
    }

    getBanner() {
        fetch('http://47.52.39.29:7001/api/banner?lng=1&week=' + new Date().getDay())
            .then(res => res.json())
            .then((result) => {
                this.setState({ banners: result.data })
            })
            .catch(console.log)
    }

    changeBanner(index){
        this.setState({ index: index });
    }

    autoBanner() {
        let superThis = this;
        let duration = 4000;
        if (id_of_setinterval > -1)
            clearInterval(id_of_setinterval)
        id_of_setinterval = setInterval(function () {
            let bannerLength = superThis.state.banners.length;
            let index = superThis.state.index;
            console.log(`>>>>>>>${index}==${bannerLength}`);
            if (index >= bannerLength - 1)
                index = -1;
            console.log(`<<<<<<${index}==${bannerLength}MMMM${index >= bannerLength - 1}`);
            index++;
            if (index >= 0) {
                superThis.setState({ index: index });
            }
            console.log(`|||||||||||||${index}==${bannerLength}`);
        }, duration);
    }

    handleHeight = () => {
        let height = document.getElementById("_topSlider").clientHeight;
        // console.log('---->' + height);
        let preHeight = height / 8;
        // console.log('---->' + preHeight);
        let li = document.getElementsByName("slide_li");
        for (var i = 0; i < li.length; i++) {
            li[i].style.height = preHeight + "px";
        }
        document.getElementById("slide_ul").style.height = height + "px";
    }

    onImgLoad(e) {
        this.handleHeight();
        //后续可以监听图片的高度
        console.log(e.target.height);
    }

    getRealLink(banner) {
        if (banner.link != null && banner.link.length > 0) {
            return banner.link;
        } else if (banner.videoId != null) {
            return "/show/" + banner.videoId;
        } else if (banner.articleId != null) {
            return "/recommend/" + banner.articleId;
        }
        return "#";
    }

    render() {
        return (
            <div class="slate_gray">
                <div class="">
                    <div class="row header_news_panel">
                        <div class="col-sm-12 tab-content tab-content_mob-p0" id="_topSlider">
                            {this.state.banners.map((banner, index) => (
                                <div role="tabpanel" class={index == this.state.index ? "tab-pane fade tab-fade in active" : "tab-pane fade tab-fade"} id={index}>
                                    <a target="_target" href={this.getRealLink(banner)}>
                                        <img onLoad={e => this.onImgLoad(e)} src={banner.imgUrl} alt="main img" class="tab-pane__img" />
                                        <div class="header_news_text tab-pane__block" />
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div class="col-sm-offset-8 news-tabs" style={{width:'25%',marginLeft:'70.6%'}}>
                            <ul id="slide_ul" class="news-tabs__nav nav nav-tabs shadow_text" role="tablist">
                                {this.state.banners.map((banner, index) => (
                                    <li name="slide_li" role="presentation" class={index == this.state.index ? "active" : ""}>
                                        <a onMouseEnter={this.changeBanner.bind(this,index)} href={this.getRealLink(banner)} class="text_no_warp">
                                            {banner.title}
                                        </a>
                                    </li>

                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopSlider;
