import React from "react";
import { translate, setLanguage } from 'react-multi-lang'

class AboutApp extends React.Component {

  state = {
    about: {
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleHeight);
    this.handleHeight();
    fetch("http://47.52.39.29:7001/api/storage?key=about-us")
      .then(res => res.json())
      .then(result => {
        this.setState({ about: result.data });
      })
      .catch(console.log);
  }

  handleHeight = () => {
    let header = document.getElementById("header");
    header.style = ("display:none");
    let footer = document.getElementById("footer");
    footer.style = ("display:none");
  }

  render() {
    return (
      <div>
        <div class="">
          <div class="">
            <div class="row">
              <div class="col-sm-12 col-md-12 p0 wrap-headline">
                <div>
                  <img src={this.state.about.imgUrl} style={{ minHeight: '200px' }} alt="img" class="wrap-headline__img" />
                </div>
              </div>

              <div class="wrap-thumbnail">
                <div class="" style={{ backgroundColor: '#ffffff', padding: '10px' }}>
                  <div class="thumbnail__news news">
                    <div dangerouslySetInnerHTML={{ __html: this.state.about.content }}>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate(AboutApp);
