import React from "react";
import { translate } from 'react-multi-lang'

class OurShows extends React.Component {
    
    componentDidMount() {
        this.getContent();
        window.addEventListener("resize", this.handleHeight);
        setTimeout(() => {
            this.handleHeight();
        }, 300);
    }

    handleHeight = () => {
        try {
            let xbns = document.getElementsByName("xbn");
            if(xbns == null) return;
            let height = xbns[0].clientHeight;
            //这里减20 是因为 thumbnail 里面有个margin-buttom = 20px
            document.getElementById("dtp").style = `height:${height*2-20}px`;
        
        } catch (error) {
            console.error(error);
        }
     }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    state = {
        showsContent: [],
        indexEvent: {}
    };

    getContent() {
        fetch('http://47.52.39.29:7001/api/video/recomend?lng=1&week=' + new Date().getDay())
            .then(res => res.json())
            .then((result) => {
                this.setState({ showsContent: result.data.list })
            })
            .catch(console.log)

        fetch("http://47.52.39.29:7001/api/storage?key=index-event")
            .then(res => res.json())
            .then(result => {
                this.setState({ indexEvent: result.data })
            })
            .catch(console.log);
    }

    render() {
        //与左边大目标对齐的个数
        var line = 4;
        var today = new Date();
        var today_date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + (today.getDay() + 1)
        return (
            <div class="wrap wrap_gray pt20 ourshow_padding_top">
                <div class="wrap wrap_gray">
                    <div class="container title">
                        <h1 class="title__h1">{this.props.t('label.ourshow')}</h1>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="thumbnail thumbnail_big">
                                    <a href={this.state.indexEvent.link != null && this.state.indexEvent.link.length > 0 ? this.state.indexEvent.link : "#"} class="thumbnail__link">
                                        <img id="dtp" src={this.state.indexEvent.imgUrl} alt="News" />
                                    </a>
                                </div>
                            </div>

                            {this.state.showsContent.map((show, index) => (
                                <div name = "xbn" class={index < line ? "col-sm-3" : "hidden"}>
                                    <div class="thumbnail" style={{marginBottom:'20px'}}>
                                        <div class="img_wap_16_9">
                                            <a href={"/show/" + show.id}>
                                                <img src={show.imgUrl} height="153" width="270" alt="News" />
                                                {/* <img src="/img/content/poster.png" height="153" width="270" alt="News" /> */}
                                            </a>
                                        </div>

                                        <div class="caption thumbnail__caption">
                                            <div class="posted">
                                                <a href={show.url}>
                                                    <span class="posted__date news__text news__category orange__text txt-1">{show.title}</span>
                                                </a>
                                                <ul class="posted__icon">
                                                    <li>
                                                        <span>
                                                            {/* <i class="icon-eye">{(show.readNum / 1000).toFixed(2)}k</i> */}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex'}}>
                    <div class="container">
                        <div class="row">
                            {this.state.showsContent.map((show, index) => (
                                <div class={index < line ? "hidden" : "col-sm-3"}>
                                    <div class="thumbnail">
                                        <div class="img_wap_16_9">
                                            <a href={"/show/" + show.id}>
                                                <img src={show.imgUrl} height="153" width="270" alt="News" />
                                                {/* <img src="/img/content/poster.png" height="153" width="270" alt="News" /> */}
                                            </a>
                                        </div>
                                        <div class="caption thumbnail__caption">
                                            <div class="posted">
                                                <a href={show.url}>
                                                    <span class="posted__date news__text news__category orange__text txt-1">{show.title}</span>
                                                </a>
                                                <ul class="posted__icon">
                                                    <li>
                                                        <span>
                                                            {/* <i class="icon-eye">{(show.readNum / 1000).toFixed(2)}k</i> */}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate(OurShows);
