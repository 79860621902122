import React from "react";
import SwiftSlider from 'react-swift-slider'

class AdsSlider extends React.Component {
    static defaultProps = {
        background: '',
      }

    state = {
        data: []
    }

    componentDidMount() {
        this.getAdvert();
    }

    getAdvert() {
        fetch('http://47.52.39.29:7001/api/advert/index?lng=1&week=' + new Date().getDay())
            .then(res => res.json())
            .then((result) => {
                let newData = [];
                let list = result.data.webOne;
                list.forEach(item => {
                    let images = item.imgUrl;
                    item.src = (item.src && item.src.length > 0) ? item.src : images;
                    newData.push(item)
                });
                this.setState({ data: newData });
                console.log(newData);
            })
            .catch(console.log)
    }

    render() {
        return (
            <div class="wrap wrap_gray" style={{background:this.props.background}}>
                <div class="container">
                    <div style={{maxHeight:'110px'}}>
                        <SwiftSlider data={this.state.data} height={100} showDots={false} />
                    </div>
                </div>
            </div>
        );
    }
}

export default AdsSlider;
