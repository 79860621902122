import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//多语言支持
import { setDefaultTranslations, setDefaultLanguage } from 'react-multi-lang'
import ch from './translations/ch.json'
import en from './translations/en.json'

setDefaultTranslations({en, ch})
setDefaultLanguage('en')
//多语言支持

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
