import React from 'react'


class Recommend extends React.Component {
    state = {
        recommend_content: {}
    }

    componentDidMount() {
        
        const { params } = this.props.match
        if (params.id != null) {
            fetch("http://47.52.39.29:7001/api/article/" + params.id)
            .then(res => res.json())
            .then(result => {
                this.setState({ recommend_content: result.data.content });
            })
            .catch(console.log);
        }
    }

    render() {
        return (
            <article class="container articles">
                <div class="row">
                <p class="current__text no-padding-left" dangerouslySetInnerHTML={{ __html : this.state.recommend_content }} />
                </div>
            </article>
        );
    }
}

export default Recommend;
