import React from 'react'
import { Icon, Popup, Grid } from 'semantic-ui-react'

const SharePopup = () => (
    <Popup style={{transition: 'top 1s ease 0s'}} trigger={<i aria-hidden="true" class="users large icon"></i>} duration flowing hoverable on='hover' position='bottom center'>
        <ul class="social__list">
            <li class="social__item">
                <a class="facebook" href="https://www.facebook.com/aattvseattle">
                    <i class="icon-facebook"></i>
                </a>
            </li>
            <li class="social__item">
                <a class="twitter" href="https://twitter.com/Aattv2">
                    <i class="icon-twitter"></i>
                </a>
            </li>
            <li class="social__item">
                <a class="youtube" href="https://www.youtube.com/channel/UCPThh1uGy3YR8IVDIdxFNhA">
                    <i class="youtube square icon"></i>                                        </a>
            </li>
            <li class="social__item">
                <a class="gplus" href="https://www.instagram.com/aat_tv/">
                    <i class="instagram icon"></i>                                        </a>
            </li>
            <li class="social__item">
                <a class="wechat" href="https://mp.weixin.qq.com/s/we2dDntBW36bvnAjbiY5Eg">
                    <i class="wechat icon"></i>                                        </a>
            </li>
        </ul>
    </Popup>
)

export default SharePopup