import React from "react";

class News extends React.Component {

    state = {
        newsContent: {}
    }

    componentDidMount() {
        const { params } = this.props.match
        if (params.id != null) {
            fetch('http://47.52.39.29:7001/api/news/content?id=' + params.id)
                .then(res => res.json())
                .then((result) => {
                    this.setState({ newsContent: result.data })
                })
                .catch(console.log)
        }
    }

    render() {
        return (
            <article class="container articles">
                <div class="row">
                    <h1 class="text-center yel_line current__title">{this.state.newsContent.title}</h1>
                    <p class="current__text no-padding-left" dangerouslySetInnerHTML={{ __html: this.state.newsContent.content }} />
                </div>
            </article>
        );
    }
}

export default News;
