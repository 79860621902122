import React from "react";
import { translate, setLanguage } from 'react-multi-lang'

class About extends React.Component {

  state = {
    about: {
    }
  }

  componentDidMount() {
    fetch("http://47.52.39.29:7001/api/storage?key=about-us")
      .then(res => res.json())
      .then(result => {
        this.setState({ about: result.data });
      })
      .catch(console.log);
  }


  render() {
    var today = new Date();
    var today_date = today.getFullYear() + '-' + (today.getMonth() + 1)
    return (
      <div>
        <div class="wrap wrap_gray">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 p0 wrap-headline">
                <img src={this.state.about.imgUrl} alt="img" class="wrap-headline__img" />
                <div class="headline clearfix">
                  <div class="headline__data">
                    <a href="category.html" class="headline__category headline__category_orange">{this.props.t('label.us')}</a>
                    <p class="headline__category">{today_date}</p>
                  </div>
                  <div class="share">
                    <ul class="share__list">
                      {/* <li class="share__row">
                        <a class="share__link share__link_bg-facebook" href="#">
                          <i class="bg bg_facebook">&ensp;</i>
                        </a>
                        <span class="share__number">{this.state.about.fb_share_number}</span>
                      </li>
                      <li class="share__row">
                        <a class="share__link share__link_bg-twitter" href="#">
                          <i class="bg bg_twitter">&ensp;</i>
                        </a>
                        <span class="share__number">{this.state.about.twitter_share_number}</span>
                      </li>
                      <li class="share__row">
                        <a class="share__link share__link_bg-google" href="#">
                          <i class="bg bg_google">&ensp;</i>
                        </a>
                        <span class="share__number">{this.state.about.google_share_number}</span>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div class="wrap-thumbnail">
                <div class="thumbnail">
                  <div class="thumbnail__news news">
                    <p class="news__desc">&nbsp;</p>
                    <div dangerouslySetInnerHTML = {{__html:this.state.about.content}}>

                    </div>
                    
                    {/* <p class="news__desc">
                      &emsp;&emsp;AATTV/亞美卫视 (原名“全美电视台”) 成立于1993年，美国华盛顿州唯一的中文电视台。总部设立于西雅图，洛杉矶和北京设有分部。在美国联邦通信委员会（FCC）其注册ID号：69571，现通过美国有线电视网络Comcast集团公司进行实时传输（Cable CH:79频道，DTV CH:22.5）。
                    </p>
                    <p class="news__desc">
                      &emsp;&emsp;电视台成立 27 年来，致力于向华盛顿州的华人社团提供24小时全年无休的优质中文电视频道，节目包罗了国际新闻、美国新闻、中国新闻，生活娱乐和教育类节目和优质电视剧，深受当地华人喜爱，也得到全球500强企业麦当劳、丰田汽车等、华盛顿州政府、西雅图市政府、国际知名非营利机构和当地企业的高度认可和广告支持，成为了美国华盛顿州最具影响力的中文媒体。
                    </p>
                    <p class="news__desc">
                      &emsp;&emsp;2019年8月，AATTV被“中视华美集团”全资收购，并于2020年1月1日全面改版为“亞美卫视”。改版后的AATTV，收看渠道更加丰富，官网（www.AATTV.com）和APP（搜索：AATTV/亚美卫视）24小时同步直播。2020年，AATTV除引入更多优质中文内容外，还将自主策划、制作：《亞美会客厅》、《玩转西雅图》、《Hi，Girls》、《Justin 自驾游中国》）四档精品节目，且陆续推出《新春音乐会》、和《中美文化旅游论坛》等系列中美文化交流活动。亚美卫视目前在西雅图拥有300平米现代化演播大厅，美国/中国两地拥有资深专业团队，主要业务包括:优质中文电视节目的美国的发行权；视频节目制作；大型活动执行；品牌战略规划、美国社交媒体账户托管等。
                    </p>
                    <p class="news__desc">
                      &emsp;&emsp;AATTV / Asian American TV was established in 1993, the only Chinese television station in Washington State, USA. Headquarters are in Seattle, Los Angeles and Beijing. The United States Federal Communications Commission (FCC) registration ID number is 69571. It’s currently transmitted in real time through the US cable television network Comcast Group (Cable CH: 79 channel, DTV CH: 22.5).
                    </p>
                    <p class="news__desc">
                      &emsp;&emsp;AATTV has established for 27 years, the station has been committed to providing 24/7 high-quality TV programs to Chinese communities in Washington state. The programs include International News, USA News, China News, Life Entertainment, Education Programs and high-quality TV drama series, which are local Chinese loves. It has also been highly recognized and supported by global top 500 companies such as McDonald's, Toyota, Washington State Government, City of Seattle, International Non-profit Organizations and local companies. AATTV has become the most influential Chinese media in Washington State in the United States.
                    </p>
                    <p class="news__desc">
                      &emsp;&emsp;In August 2019, AATTV was acquisition by "Zhongshi Huamei Group", and was fully revised to new "Asian American T.V." on January 1, 2020. The new AATTV has more abundant way to viewing, including the official website (www.AATTV.com) and the APP (search: AATTV / Asian American TV) with 24/7 live broadcasting. In 2020, in addition to introducing more high-quality Chinese content, AATTV will produce independently: "Asian American Club Room", "Have Fun in Seattle", "Hi, Girls", and "Justin Self-driving in China". Also launch a series of China-US cultural exchange activates such as "Chinese New Year Concert" and "China-US Cultural Tourism Forum". Asian American T.V. currently has a 300-square-meter modern studio in Seattle and has a senior professional team in both China and USA. The main businesses include: distribution rights for high-quality Chinese TV programs in USA; video program production; large-scale event execution; brand strategic planning, social media account hosting in USA and more.
                    </p>
                    
                    <p class="news__desc">&nbsp;</p>
                    <p class="news__desc">
                      联系方式：
                    </p>

                    <p>
                      <i class="teal map marker icon"></i>
                      {this.state.about.address}
                    </p>
                    <p>
                      <i class="teal phone icon"></i>
                      {this.state.about.phone}
                    </p>
                    <p>
                      <i class="teal fax icon"></i>
                      {this.state.about.fax}
                    </p>
                    <p>
                      <i class="teal mail icon"></i>
                      {this.state.about.email}
                    </p>

                    <img src="https://aatv-file.oss-cn-beijing.aliyuncs.com/img/76b7e5f0-1d99-250f-032e-309b24dca4bc.jpg" width="130" alt="News"/>

                    <p>AATTV | DTV CH: 22.5   Cable CH: 79</p>
                    <p>Only 24*7 Asian Channel in WA</p> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate(About);
