import React from "react";
import Iframe from 'react-iframe';
import { translate } from 'react-multi-lang'
import ShowVideoList from './ShowVideoList';

class TV extends React.Component {

    state = {
        width: 0,
        height: 0,
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleHeight);
        this.handleHeight();
    }

    handleHeight = () => {
        let width = document.getElementById("header").clientWidth * 0.6;
        // if (width > 800) {
        //     this.setState({ width: 800 });
        //     this.setState({ height: 800 / 16 * 9 });
        // } else {
        //     this.setState({ width: width });
        //     this.setState({ height: width / 16 * 9 });
        // }
        this.setState({ width: width });
        this.setState({ height: width / 16 * 9 });
    }

    render() {
        return (
            <section>
                <div class="container">
                    <div class="iframe_wrapper ">
                        <div class="row">
                            <div class="caption thumbnail__caption col-sm-9">
                                <div class="iframe_wrapper center">
                                    <h3 class="text-center current__title">
                                        {this.props.t("label.tv")}
                                    </h3>
                                    <div style={{ display: 'flex' }}>
                                        {/* src="//iframe.dacast.com/b/91181/c/448331" */}
                                        <Iframe
                                            src="./live.html"
                                            width={this.state.width}
                                            height={this.state.height}
                                            frameborder="0"
                                            scrolling="no"
                                            allow="fullscreen"
                                            allowfullscreen
                                            webkitallowfullscreen
                                            mozallowfullscreen
                                            oallowfullscreen
                                            msallowfullscreen>
                                        </Iframe>
                                    </div>
                                </div>
                            </div>
                            <ShowVideoList />
                        </div>
                    </div>

                </div>

            </section>
        );
    }
}


export default translate(TV);