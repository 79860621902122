import React from "react";
import { translate, setLanguage } from "react-multi-lang";
import AppPopup from "./AppClient";
import SharePopup from "./ShareClient";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beijing: "",
      sf: "",
      st: ""
    };
    this.setDefaultLang();
  }

  componentDidMount() {
    fetch("http://47.52.39.29:7001/api/weather")
      .then(res => res.json())
      .then(result => {
        this.setState({ la: result.data.LosAngeles.tmp });
        this.setState({ beijing: result.data.beijing.tmp });
        this.setState({ st: result.data.Seattle.tmp });
      })
      .catch(console.log);
  }

  setDefaultLang() {
    let language = localStorage.getItem('language');
    if (language)
      this.changeLang(language);
  }

  changeLang(lang) {
    setLanguage(lang);
    localStorage.setItem('language', lang);
  }

  render() {
    return (
      // <header className="App-header">
      //     <h1 className="App-title">
      //         {this.props.t('label.title', {param: 'react'})}
      //     </h1>
      // </header>
      <header id="header" class="header">
        <div class="header__top">
          <div class="container">
            <div class="row">
              <div class="col-sm-3 col-xs-6">
                <a href="/home">
                  <img class="aattv-logo" href="/home" src="https://aatv-file.oss-cn-beijing.aliyuncs.com/img/5af8a0ad-d422-61fa-eae1-b423aee86fde.png" />
                </a>
              </div>
              <div class="col-sm-6 col-md-5 hidden-xs search_box">
                <form>
                  <div>
                    {/* <input
                      type="text"
                      class="form-control"
                      placeholder={this.props.t("label.search")}
                    />
                    <button type="submit" class="btn btn-search">
                      <i class="icon-search"></i>
                    </button> */}
                  </div>
                </form>
              </div>

              <div class="col-sm-offset-2 col-md-offset-0 col-sm-6 col-md-4 hidden-xs">
                <div class="col-xs-2 col-sm-2 float__right">
                  <div class="exchange" style={{padding:'25px 0 0px'}}>
                    <p class="exchange__course">
                      {/* <i aria-hidden="true" class="language large icon"></i> */}
                      <button style={{width:'30px',height:'30px'}} onClick={() => this.changeLang("en")}>
                        {" "}
                        EN{" "}
                      </button>
                      <button style={{width:'30px',height:'30px'}} onClick={() => this.changeLang("ch")}>
                        {" "}
                        中{" "}
                      </button>
                    </p>
                  </div>
                </div>

                <div class="col-xs-2 col-sm-2 float__right">
                  <div class="exchange" style={{padding:'25px 0 0px'}}>
                    <p class="exchange__course">
                      {/* <a href="/about"> */}
                        {/* <i aria-hidden="true" class="users large icon"></i> */}
                      <SharePopup/>
                        {/* {this.props.t("label.contact")} */}
                      {/* </a> */}
                    </p>
                  </div>
                </div>
                <div class="col-xs-2 col-sm-2 float__right">
                  <div class="exchange" style={{padding:'20px 0 0px'}}>
                    <p class="exchange__course">
                      {/* <img src='/img/content/app.png' height="30px"></img> */}
                      <AppPopup/>
                    </p>
                  </div>
                </div>
                {/* <div class="col-xs-2 col-sm-2 float__right">
                  <div class="exchange">
                    <p class="exchange__course">
                      <a href="#" onClick={() => this.changeLang("ch")}>
                        <i aria-hidden="true" class="home large icon"></i>
                        {this.props.t("label.login")}
                      </a>
                    </p>
                  </div>
                </div> */}

                {/* <div class="col-xs-4 col-sm-5 float__right">
                  <div class="weather">
                    <div class="weather__temperature">
                      <span class="icon-sun"></span>
                      <em id="Beijing" class="active">
                        {this.state.beijing} ℃
                      </em>
                      <em id="Seattle">{this.state.st} ℃</em>
                      <em id="LosAngeles">{this.state.la} ℃</em>
                    </div>
                    <div class="weather__city">
                      <em>北京</em>
                      <div class="weather__city__list">
                        <ul>
                          <li class="active">
                            <a href="#">北京</a>
                          </li>
                          <li>
                            <a href="#">Seattle</a>
                          </li>
                          <li>
                            <a href="#">Los Angeles</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div class="wsmenucontent overlapblackbg"></div>
        <div class="wsmenuexpandermain slideRight">
          <a id="navToggle" class="animated-arrow slideLeft">
            <span></span>
          </a>
        </div>
        <div class="header_down color_match">
          <div class="container">
            <div class="wrapper clearfix bigmegamenu">
              <nav class="wsmenu slideLeft clearfix">
                <ul class="mobile-sub wsmenu-list">
                  <li class="visible-xs">
                    <form class="navbar-form mob_search" role="search">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search"
                        />
                      </div>
                      <button type="submit" class="btn btn-search">
                        <i class="icon-search"></i>
                      </button>
                    </form>
                  </li>

                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/home">{this.props.t("label.home")}</a>
                  </li>
                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/newsHome">{this.props.t("label.news")}</a>
                  </li>
                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/tv">{this.props.t("label.tv")}</a>
                  </li>
                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/events">{this.props.t("label.newEvents")}</a>
                  </li>
                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/schedule">{this.props.t("label.tvlist")}</a>
                  </li>
                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/shows">{this.props.t("label.shows")}</a>
                  </li>
                  {/* <li>
                    <span class="wsmenu-click"></span>
                    <a href="/shows">{this.props.t("label.documentary")}</a>
                  </li>
                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/shows">{this.props.t("label.entertainment")}</a>
                  </li>
                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/shows">{this.props.t("label.zongyi")}</a>
                  </li>
                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/shows">{this.props.t("label.kid")}</a>
                  </li>
                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/shows">{this.props.t("label.life")}</a>
                  </li>
                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/shows">{this.props.t("label.education")}</a>
                  </li>
                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/shows">{this.props.t("label.tvshows")}</a>
                  </li>
                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/shows">{this.props.t("label.fashion")}</a>
                  </li> */}
                  <li>
                    <span class="wsmenu-click"></span>
                    <a href="/about">{this.props.t("label.us")}</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default translate(Header);
