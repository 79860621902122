import React from "react";

class NewsList extends React.Component {

    static defaultProps = {
        from: 'News',
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    state = {
        quickNews: [],
        breakingNews: [],
        //一排四个 默认显示三排 则是12个下标是从0开始
        mewsInitShowMuner: 11,
        showMoreButton: true
    }

    componentDidMount() {
        this.getBulletin();
    }


    getBulletin() {
        fetch('http://47.52.39.29:7001/api/bulletin')
            .then(res => res.json())
            .then((result) => {
                let data = result.data.list;
                let newData = this.state.quickNews;
                data.map((item, index) => {
                    item.href = "/recommend/" + item.id;
                    newData.push(item);
                });
                this.setState({ quickNews: newData });
                let from = this.props.from;
                switch (from) {
                    case "News":
                        this.getColumn();
                        break;
                    case "Home":
                        this.getHomeColumn();
                        break;
                }
            })
            .catch(console.log)
    }

    getColumn() {
        fetch('http://47.52.39.29:7001/api/news/list?pagesize=37')
            .then(res => res.json())
            .then((result) => {
                let data = result.data.list;
                let newData = this.state.quickNews;
                data.map((item, index) => {
                    item.imgUrl = item.imgUrl;
                    item.desc = item.desc;
                    item.href = "/news/" + item.id;
                    newData.push(item);
                });
                this.setState({ quickNews: newData });
            })
            .catch(console.log)
    }

    getHomeColumn() {
        fetch('http://47.52.39.29:7001/api/news/column')
            .then(res => res.json())
            .then((result) => {
                let data = result.data;
                let newData = this.state.quickNews;
                data.map((item, index) => {
                    item.imgUrl = item.news[0].imgUrl;
                    item.desc = item.news[0].desc;
                    item.href = "/news/" + item.news[0].id;
                    newData.push(item);
                });
                this.setState({ quickNews: newData });
            })
            .catch(console.log)
    }

    showMore() {
        this.setState({ mewsInitShowMuner: 10000 });
        this.setState({ showMoreButton: false });
    }

    render() {
        return (
            <div class="wrap wrap_gray pt20">
                <div class="container">
                    <div class="row">
                        {this.state.quickNews.map((news, index) =>
                            <div class="col-sm-3 fixed_259" style={{ display: index > this.state.mewsInitShowMuner ? "none" : "block" }}>
                                <div class="thumbnail">
                                    <div class="image_container">
                                        <div class="img_wap_16_9">
                                            <a href={news.href} class="">
                                                <img src={news.imgUrl} height="153" width="270" alt="News" />
                                                {/* <img src="/img/content/poster.png" height="153" width="270" alt="News" /> */}
                                            </a>
                                        </div>
                                        
                                        <p class="bottom-left news_tag">{news.title}</p>
                                    </div>
                                    <div class="news-0  thumbnail__caption">
                                        <div class="news caption__news">
                                            <a href={news.href} class="news__link">
                                                <p class="news__text txt-2">{news.desc}</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>
                <div class="ajax_load" onClick={this.showMore.bind(this)} style={{ display: this.state.showMoreButton ? "block" : "none" }}>
                    <i class="icon-arrows-cw"></i>更多
                        <svg width="128" height="40" viewBox="0 0 128 40" xmlns="http://www.w3.org/2000/svg">
                        <rect x='0' y='0' fill='none' width='128' height='40'></rect>
                    </svg>
                </div>
            </div>
        );
    }
}

export default NewsList;
