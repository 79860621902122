import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './marquee.css';

export const raf = () => {
  var lastTime = 0;
  var vendors = ['webkit', 'moz'];
  for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
    window.cancelAnimationFrame =
    window[vendors[x] + 'CancelAnimationFrame'] || window[vendors[x] + 'CancelRequestAnimationFrame'];
  }

  if (!window.requestAnimationFrame)
    window.requestAnimationFrame = function (callback, element) {
      var currTime = new Date().getTime();
      var timeToCall = Math.max(0, 16 - (currTime - lastTime));
      var id = window.setTimeout(function () { callback(currTime + timeToCall); },
        timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    };

  if (!window.cancelAnimationFrame)
    window.cancelAnimationFrame = function (id) { clearTimeout(id) };
}

export default class Marquee extends Component {
  static defaultProps = {
    direction: 'vertical',
    /* 高度 */
    verticalItemHeight: '50px',
    /*停顿时间 ms*/
    stopDuration:3000,
    /* 滚动时间 ms*/
    rollDuration:3000
  }

  constructor(props) {
    super(props);

    this.timerMarquee = null;
    this.domMi = null;
    this.domMw = null;

    this.state = {};
  }

  initMarquee = () => {
    this.stopMarquee();
    this.runMarquee();
  }

  //横向滚动
  landscapeMarquee = () => {
    this.domMw.scrollLeft >= this.domMi.scrollWidth ? this.domMw.scrollLeft = 0 : this.domMw.scrollLeft++;
    this.timerMarquee = requestAnimationFrame(this.landscapeMarquee);
  }

  //竖向滚动
  verticalMarquee = () => {
    this.domMw.scrollTop >= this.domMi.scrollHeight ? this.domMw.scrollTop = 0 : this.domMw.scrollTop++;
    /* 文本滚动到居中的时候 进行停顿stopDuration秒后再次进行滚动 */
    /* (Math.floor(this.domMw.scrollTop) % Math.floor(this.domMw.offsetHeight)) == 0 表示滚动与原始高度一致 即居中了 */
    let time = 0.000;
    
    if(Math.floor(this.domMw.scrollTop) != 0 && (Math.floor(this.domMw.scrollTop) % Math.floor(this.domMw.offsetHeight)) == 0){
      time = this.props.stopDuration;
    }else{
      /* rollDuration 总的滚动时间 / 2(由于中间会有停顿动画执行，所以/2) / 总滚动的高度 == 每px执行动画的时间 */
      time = this.props.rollDuration / 2 / this.domMi.scrollHeight;
    }
    setTimeout(() => {
      this.timerMarquee = requestAnimationFrame(this.verticalMarquee);
    }, time);
  }

  // 运动
  runMarquee = () => {
    this.stopMarquee();
    if (this.props.direction === 'vertical') {
      this.timerMarquee = requestAnimationFrame(this.verticalMarquee);
    } else {
      this.timerMarquee = requestAnimationFrame(this.landscapeMarquee);
    }
  }

  //暂停
  stopMarquee = () => {
    this.timerMarquee && cancelAnimationFrame(this.timerMarquee)
  }

  componentDidMount = () => {
    this.initMarquee();

    let { getMarquee } = this.props

    getMarquee && getMarquee({
      runMarquee: this.runMarquee,
      stopMarquee: this.stopMarquee
    });

  }

  componentWillUnmount = () => {
    this.stopMarquee();
  }

  renderLandscapeMarquee() {
    let { loopData } = this.props;

    return (
      <div className="marquee-landscape-wrap" ref={(mw) => { this.domMw = mw; }}>
        <div className="marquee-landscape-item" ref={(mi) => { this.domMi = mi; }}>
          {loopData.map((item, index) => (<div className="marquee-landscape-txt" key={index}>{item.txt}</div>))}
        </div>
        <div className="marquee-landscape-item">
          {loopData.map((item, index) => (<div className="marquee-landscape-txt" key={index}>{item.txt}</div>))}
        </div>
      </div>
    )
  }

  /**
   * item 点击事件
   * @param {*} item 
   * @param {*} index 
   */
  renderVerticalRow(item,index){
    let { verticalItemHeight } = this.props;
    return(
      <a href={"/recommend/" + item.id} class="thumbnail__link" style={{color:'#E0E0E0'}}>
        <div style={{ height: verticalItemHeight, lineHeight: verticalItemHeight }} className="marquee-vertical-txt" key={index}>
        <img style={{width:20,marginRight:5}} src="img/content/notice.png"/>  {item.txt}
        </div>
      </a>
    )
  }

  renderVerticalMarquee() {
    let { loopData } = this.props;
    return (
      <div className="marquee-vertical-wrap" ref={(mw) => { this.domMw = mw; }}>
        
          <div className="marquee-vertical-item" ref={(mi) => { this.domMi = mi; }}>
            {loopData.map((item, index) => (this.renderVerticalRow(item,index)))}
          </div>
          <div className="marquee-vertical-item">
            {loopData.map((item, index) => (this.renderVerticalRow(item,index)))}
          </div>
      </div>
    )
  }

  render() {
    raf();
    let { direction } = this.props;

    return (
      <div className="react-marquee-box">
        {direction === 'landscape' ? this.renderLandscapeMarquee() : this.renderVerticalMarquee()}
      </div>
    )
  }
}

Marquee.propTypes = {
  loop: PropTypes.bool,
  loopData: PropTypes.array,
  getMarquee: PropTypes.func,
  direction: PropTypes.string,
  verticalItemHeight: PropTypes.string
};