import React from "react";
import { Player, LoadingSpinner } from 'video-react';
import ShowVideoList  from './ShowVideoList';

class Show extends React.Component {
  state = {
    show: [],
    width: 0,
    height: 0,
  };

  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    window.addEventListener("resize", this.handleHeight);
    this.handleHeight();
    const { params } = this.props.match;
    fetch("http://47.52.39.29:7001/api/video/" + params.id)
      .then(res => res.json())
      .then(result => {
        this.setState({ show: result.data });
      })
      .catch(console.log)
  }

  handleStateChange(state) {
    // copy player state to this component's state
    // console.log(state);
    if (state.waiting) {

    }
  }

  handleHeight = () => {
    let width = document.getElementById("header").clientWidth * 0.6;
    // if (width > 800) {
    //   this.setState({ width: 800 });
    //   this.setState({ height: 800 / 16 * 9 });
    // } else {
    //   this.setState({ width: width });
    //   this.setState({ height: width / 16 * 9 });
    // }

    this.setState({ width: width });
    this.setState({ height: width / 16 * 9 });
    this.setState({ cellWidth: width / 16 * 9 });
  }

  render() {
    return (
      <section>
        <div class="container">
          <div class="iframe_wrapper">
            <div class="row">
              <div class="caption thumbnail__caption col-sm-9">
                <div class="iframe_wrapper center">
                  <h3 class="text-center current__title">
                    {this.state.show.title}
                  </h3>
                  <Player ref={player => {
                    this.player = player;
                  }}
                    playsInline
                    // poster="/img/content/poster.png"
                    // src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                    poster={this.state.show.imgUrl}
                    src={this.state.show.videoUrl}
                    fluid={false}
                    width={this.state.width}
                    height={this.state.height}
                    autoPlay='true'
                  >
                    <LoadingSpinner />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: this.state.width, height: this.state.height }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <img class="aattv-logo" src="https://aatv-file.oss-cn-beijing.aliyuncs.com/img/5af8a0ad-d422-61fa-eae1-b423aee86fde.png" />
                        <img class="aattv-logo" src="img/content/soogif.gif" />
                      </div>
                    </div>
                  </Player>
                  <div style={{ width: this.state.width }}>
                    <div class="thumbnail__caption">
                      <div class="news caption__news" style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }} class="col-sm-2">
                          <label>视频简介：</label>
                          {/* <ul class="posted__icon" style={{ width: '80px', background: '#E91802', borderRadius: '5px', marginTop: '10px', alignItems: 'center', display: 'flex' }}>
                            <li>
                              <span>
                                <i class="icon-eye" style={{ color: '#fff' }}>{(this.state.show.readNum / 1000).toFixed(2)}k</i>
                              </span>
                            </li>
                          </ul> */}
                        </div>
                        <p class="text col-sm-10">
                          {this.state.show.desc && this.state.show.desc.length > 0 ? this.state.show.desc : "暂无描述"}
                        </p>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <ShowVideoList/>
            </div>
          </div>

        </div>
      </section>
    );
  }
}

export default Show;
