import React from "react";
import NewsList from "./NewsList";

class NewsHome extends React.Component {

    state = {
    }

    componentDidMount() {
    }

    render() {
        return (
            <section>
                <NewsList />
            </section>
        )
    }
}

export default NewsHome;
