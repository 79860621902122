import React from "react";

class Shows extends React.Component {
  state = {
    showsContent: []
  };

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  componentDidMount() {
    fetch("http://47.52.39.29:7001/api/video?pagesize=1000&lng=1&tagId=&marker=")
      .then(res => res.json())
      .then(result => {
        this.setState({ showsContent: result.data.list });
      })
      .catch(console.log);
  }

  render() {
    return (
      <div class="container pt20">
        <div class="row" style={{ marginBottom: '60px' }}>

          {this.state.showsContent.map(show => (

            <div class="col-sm-3 fixed_259">
              <div class="thumbnail">
                <div class="img_wap_16_9">
                  <a href={"/show/" + show.id} class="">
                    <img src={show.imgUrl} height="153" width="270" alt="News" />
                    {/* <img src="/img/content/poster.png" height="153" width="270" alt="News" /> */}
                  </a>
                </div>
                <div class="news-0 thumbnail__caption">
                  <div class="news caption__news">
                    {/* <i class="icon-play"></i> */}
                    <a href={"/show/" + show.id} class="news__link">
                      <p class="news__category yellow-line txt-1">{show.title}</p>
                    </a>
                  </div>
                  {/*<div class="posted">
                    <span class="posted__date">
                      {show.publishAt.substring(0, 10)}
                    </span>
                     <ul class="posted__icon">
                      <li>
                        <span>
                          <i class="icon-comment-empty"></i>17
                        </span>
                      </li>
                      <li>
                        <span>
                          <i class="icon-eye"></i>{this.getRandomInt(10, 20)/10}k
                            </span>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Shows;
