import React from "react";

class Schedule extends React.Component {

  state = {
    schedules: ""
  }

  componentDidMount() {
    fetch('http://47.52.39.29:7001/api/programme/all?lng=1&week=' + new Date().getDay())
      .then(res => res.json())
      .then((result) => {
        this.setState({ schedules: result.data.web })
      })
      .catch(console.log)
  }

  render() {
    return (
      <section>
        <div class="container">
          <div class="col-sm-12 wrap-redaction wrap-redaction_white">
            <div class="thumbnail__caption">
              <div class="container title redaction__category"><h1 class="news__category  title__h1">今日节目表</h1></div>
{/* 
              {this.state.schedules.map(schedule =>
                <div class="news caption__news">
                  <p class="news__head news__category">
                    <div class="col-sm-3">{schedule.playTime}</div>
                    <div class="col-sm-9"><p class="news__head">{schedule.title}</p></div>
                  </p>
                </div>
              )} */}

            <img src={this.state.schedules} alt="News"/>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Schedule;
