import React from "react";
import { translate } from 'react-multi-lang'

class Events extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });  
  }

  handleSubmit(event) {
    const formData = new FormData(event.target)
    const user = {}
    event.preventDefault()
    for (let entry of formData.entries()) {
        user[entry[0]] = entry[1]
    }
    var opts = {
      method:"POST",   //请求方法
      body:JSON.stringify(user),   //请求体
　　　　　headers: {
 　　　　 'Accept': 'application/json',
 　　　　 'Content-Type': 'application/json',
　　　　 },
  }
    fetch('http://47.52.39.29:7001/api/event/film',opts)
                .then(res => res.json())
                .then((result) => {
                  if(result.status === 0){
                    alert("提交成功！");
                  }else{
                    alert("暂时无法提交您的信息，请联系管理员！"+result.status);
                  }
                })
                .catch(console.log)
  }

  render() {
    return (
      <div class="container">
          <div class="wrap_grey">
            <div class="container title news__caption wrap-logo">
                <h2 class="title__h1">Resilient Visions 2020 Registration</h2>
            </div>
          </div>
          <div class="wrap">
            <div class="container title">
                <h3 class="title">个人信息/Personal Info</h3>
            </div>
          </div>
        <form onSubmit={this.handleSubmit}>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="name"><i class="teal user icon"></i></label>
              <input type="text" class="form-control" name="name" placeholder="姓名/Full Name" onChange={this.handleChange}/>
            </div>
            <div class="form-group col-md-6">
              <label for="age"><i class="teal birthday cake icon"></i></label>
              <input type="text" class="form-control" name="age" placeholder="年龄/Age" onChange={this.handleChange}/>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="nationality"><i class="teal globe icon"></i></label>
              <input type="text" class="form-control" name="nationality" placeholder="国籍/Nationality" onChange={this.handleChange}/>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="school"><i class="teal university icon"></i></label>
            <input type="text" class="form-control" name="school" placeholder="学校/School" onChange={this.handleChange}/>
          </div>
          <div class="form-group col-md-6">
            <label for="email"><i class="teal mail icon"></i></label>
            <input type="email" class="form-control" name="email" placeholder="邮件/Email" onChange={this.handleChange}/>
          </div>
          <div class="form-group col-md-6">
            <label for="phone"><i class="teal phone icon"></i></label>
            <input type="text" class="form-control" name="phone" placeholder="电话/Phone" onChange={this.handleChange}/>
          </div>
          <div class="wrap">
            <div class="container title">
                <h3 class="title">作品/Project Info</h3>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="projectName"><i class="teal film icon"></i></label>
            <input type="text" class="form-control" name="projectName" placeholder="作品名称/Project Name" onChange={this.handleChange}/>
          </div>
          <div class="form-group col-md-6">
            <label for="timeLength"><i class="teal clock icon"></i></label>
            <input type="text" class="form-control" name="timeLength" placeholder="时长/Time Length" onChange={this.handleChange}/>
          </div>
          <div class="form-group col-md-6">
            <label for="language"><i class="teal language icon"></i></label>
            <input type="text" class="form-control" name="language" placeholder="作品语言/Language" onChange={this.handleChange}/>
          </div>
          <div class="form-group col-md-6">
            <label for="projectLinks"><i class="teal linkify icon"></i></label>
            <input type="text" class="form-control" name="projectLinks" placeholder="视频相关链接/Video Links" onChange={this.handleChange}/>
          </div>
          <div class="form-group col-md-12">
            <label class="news_tag" for="summary"><i class="teal sticky note outline icon"></i></label>
            <input type="text" class="form-control" name="summary" placeholder="故事梗概/Summary" onChange={this.handleChange}/>
          </div>
          <div class='news__caption'>
            <button type="submit" class="btn btn-primary">注册/Register</button>
          </div>
        </form>
      </div>
    );
  }
}

export default translate(Events);