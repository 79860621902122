import React from "react";
import AdsSlider from "./AdsSlider";

class Footer extends React.Component {
    render() {
        return (
            <footer id="footer" class="footer slate_gray">
                <AdsSlider background="#2f3031" />
                <div class="container">
                    <div class="">
                        {/* <div>
                            <img class="col-sm-8 ads_bottom" src="https://aatv-file.oss-cn-beijing.aliyuncs.com/img/fbd9b9fd-2df6-ff54-c808-ef654cb726b6.jpg" />
                        </div>
                        <div>
                            <img class="col-sm-1 ads_bottom float__right" src="https://aatv-file.oss-cn-beijing.aliyuncs.com/img/55be4e77-fa41-df6a-e6f5-40377db95472.png" />
                        </div>
                        <div>
                            <img class="col-sm-2 ads_bottom float__right" src="https://aatv-file.oss-cn-beijing.aliyuncs.com/img/11ee4c4a-3f9a-8519-7841-5bd0814777dc.png" />
                        </div>
                        <div>
                            <img class="col-sm-2 ads_bottom float__right" src="https://aatv-file.oss-cn-beijing.aliyuncs.com/img/IMG_6915.PNG" />
                        </div> */}
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <p class="copyright">Copyright &copy; 2020.AATTV All rights reserved.</p>
                        </div>
                        <div class="col-sm-6">
                            <div class="social navbar-right">
                                <p class="social__text">We are in social networks</p>
                                <ul class="social__list">
                                    <li class="social__item">
                                        <a class="facebook" href="https://www.facebook.com/aattvseattle">
                                            <i class="icon-facebook"></i>
                                        </a>
                                    </li>
                                    <li class="social__item">
                                        <a class="twitter" href="https://twitter.com/Aattv2">
                                            <i class="icon-twitter"></i>
                                        </a>
                                    </li>
                                    <li class="social__item">
                                        <a class="youtube" href="https://www.youtube.com/channel/UCPThh1uGy3YR8IVDIdxFNhA">
                                            <i class="youtube square icon"></i>                                        </a>
                                    </li>
                                    <li class="social__item">
                                        <a class="gplus" href="https://www.instagram.com/aat_tv/">
                                            <i class="instagram icon"></i>                                        </a>
                                    </li>
                                    <li class="social__item">
                                        <a class="wechat" href="https://mp.weixin.qq.com/s/we2dDntBW36bvnAjbiY5Eg">
                                            <i class="wechat icon"></i>                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
