import React from 'react'
import { Icon, Popup, Grid } from 'semantic-ui-react'

const AppPopup = () => (
    <Popup style={{transition: 'top 1s ease 0s'}} trigger={<img src='/img/content/app.png' height="30px"></img>} duration flowing hoverable on='hover' position='bottom center'>
        <Grid centered divided columns={2}>
            <Grid.Column textAlign='center'>
                <div>
                    <a target="AATTV" href="https://apps.apple.com/app/apple-store/id1581227288">
                        <img style={{ marginBottom: '0px' }} class="col-sm-1 ads_bottom float__right" src="https://aatv-file-new.oss-cn-beijing.aliyuncs.com/qrcode/app_store.png" />
                    </a>
                    <p>IOS</p>
                </div>
            </Grid.Column>
            <Grid.Column textAlign='center'>
                <div>
                    <a target="AATTV" href="https://aatv-file-new.oss-cn-beijing.aliyuncs.com/apk/__UNI__9B54DD8_0504170726.apk?versionId=CAEQIhiBgIC5hqy5hBgiIDU3YTYxYTkwZjNkZjRjYjY5NjViZmFiMTE0MjIwZTQ0">
                        <img style={{ marginBottom: '0px' }} class="col-sm-2 ads_bottom float__right" src="https://aatv-file-new.oss-cn-beijing.aliyuncs.com/qrcode/android_general.png" />
                    </a>
                    <p>Android</p>
                </div>
            </Grid.Column>
        </Grid>
    </Popup>
)

export default AppPopup