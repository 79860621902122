import React from "react";
import { List } from 'react-virtualized';
import { translate } from 'react-multi-lang'

class ShowVideoList extends React.Component {

    state = {
        width: 0,
        height: 0,
        showsContent: [],
        cellHeight: 190,
        cellWidth: 270,
        qrcodeHeight:130
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleHeight);
        this.handleHeight();

        fetch("http://47.52.39.29:7001/api/video?lng=1&tagId=&marker=")
            .then(res => res.json())
            .then(result => {
                this.setState({ showsContent: result.data.list });
            })
            .catch(console.log);
    }

    handleHeight = () => {
        let width = document.getElementById("header").clientWidth * 0.6;
        // if (width > 800) {
        //     this.setState({ width: 800 });
        //     this.setState({ height: 800 / 16 * 9 });
        // } else {
        //     this.setState({ width: width });
        //     this.setState({ height: width / 16 * 9 });
        // }
        this.setState({ width: width });
        this.setState({ height: width / 16 * 9 });
    }

    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it)
    }) {
        let show = this.state.showsContent[index];
        //第一个特殊处理，有个二维码显示
        if (index == 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: this.state.cellHeight, position: "fixed", }}>
                        <div class="thumbnail thumbnail_small">
                            <a href={"/show/" + show.id} class="thumbnail__link">
                                <img src={show.imgUrl} height="153" width="270" alt="News" />
                            </a>
                            <div class="news-0 thumbnail__caption">
                                <div class="news caption__news">
                                    {/* <i class="icon-play"></i> */}
                                    <a href={"/show/" + show.id} class="news__link">
                                        <p class="news__category yellow-line">{show.title}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: this.state.qrcodeHeight, position: "fixed", marginTop: this.state.cellHeight-10}}>
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <div>
                                <a target="AATTV" href="https://apps.apple.com/us/app/aattv/id1234702131">
                                    <img style={{ marginBottom: '0px' }} class="col-sm-1 ads_bottom float__right" src="https://aatv-file.oss-cn-beijing.aliyuncs.com/img/55be4e77-fa41-df6a-e6f5-40377db95472.png" />
                                </a>
                                <p>IOS</p>
                            </div>
                            <div>
                                <a target="AATTV" href="https://play.google.com/store/apps/details?id=com.huamei.aatv">
                                    <img style={{ marginBottom: '0px' }} class="col-sm-2 ads_bottom float__right" src="https://aatv-file.oss-cn-beijing.aliyuncs.com/img/11ee4c4a-3f9a-8519-7841-5bd0814777dc.png" />
                                </a>
                                <p>Android</p>
                            </div>
                        </div>
                        <p>
                            扫一扫，下载观看</p>
                    </div>
                </div>
            );
        }
        return (
            <div style={{ height: this.state.cellHeight, position: "fixed", marginTop: this.state.cellHeight * index + this.state.qrcodeHeight }}>
                <div class="thumbnail thumbnail_small">
                    <a href={"/show/" + show.id} class="thumbnail__link">
                        <img src={show.imgUrl} height="153" width="270" alt="News" />
                    </a>
                    <div class="news-0 thumbnail__caption">
                        <div class="news caption__news">
                            {/* <i class="icon-play"></i> */}
                            <a href={"/show/" + show.id} class="news__link">
                                <p class="news__category yellow-line">{show.title}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div class="col-sm-2 thumbnail__caption" style={{ marginLeft: '-40px' }}>
                <h3 class="text-center current__title" style={{ color: '#00000000' }}>
                    {this.props.t("label.tv")}
                </h3>
                <List
                    width={this.state.cellWidth}
                    height={this.state.height}
                    rowCount={this.state.showsContent.length}
                    rowHeight={this.state.cellHeight}
                    rowRenderer={this.rowRenderer.bind(this)}
                />
            </div>
        );
    }
}


export default translate(ShowVideoList);