import React from "react";
import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import News from "./News";
import Shows from "./Shows";
import Show from "./Show";
import Schedule from "./Schedule";
import About from "./About";
import AboutApp from "./AboutApp";
import TV from "./TV";
import Agreement from "./Agreement";
import Events from "./Events";
import NewsHome from "./NewsHome";
import Recommend from "./Recommend";
import { translate, setLanguage } from "react-multi-lang";
import { BrowserRouter as Router, Route } from "react-router-dom";

class App extends React.Component {
  changeLang(lang) {
    setLanguage(lang);
  }
  render() {
    return (
      <Router>
        <div className="App">
          <Header />
          <Route exact path="/" component={Home} />
          <Route path="/news/:id" component={News} />
          <Route path="/newsHome" component={NewsHome} />
          <Route exact path="/shows" component={Shows} />
          <Route exact path="/show/:id" component={Show} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/schedule" component={Schedule} />
          <Route exact path="/about" component={About} />
          <Route exact path="/tv" component={TV} />
          <Route exact path="/agreement" component={Agreement} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/recommend/:id" component={Recommend} />
          <Route exact path="/about_app" component={AboutApp} />
          <Footer />
        </div>
      </Router>
    );
  }
}

export default translate(App);
