import React from "react";
import { translate } from 'react-multi-lang'
import TopSlider from "./TopSlider";
import Marquee from './Marquee'
import OurShows from "./OurShows";
import AdsSlider from "./AdsSlider";
import NewsList from "./NewsList";

class Home extends React.Component {

    state = {
        quickRollNews: [],
    }

    componentDidMount() {
        fetch('http://47.52.39.29:7001/api/quicknews')
            .then(res => res.json())
            .then((result) => {
                var data = result.data;
                for (let i = 0; i < data.length; i++) {
                    data[i].txt = data[i].desc;
                }
                this.setState({ quickRollNews: data });
            })
            .catch(console.log)
    }

    render() {
        return (
            <section>
                <TopSlider />
                <div class="wrap wrap_gray">
                    <div style={{ display: 'flex' }}>
                        <div class="container">
                            <div class="row" style={{ marginTop: 13, marginBottom: 13, }}>
                                <div style={{ backgroundColor: '#2F2F2F', height: '40px' }}>
                                    <div class="quick_news_tag col-sm-2">
                                        <label style={{ backgroundColor: "#00000000", width: '100%' }} class="quick_news_tag">{this.props.t('label.quicknew')}</label>
                                    </div>

                                    <div class="col-sm-9 quick_news_tag_line">
                                        {/* <img style={{width:20,marginRight:5}} src="img/content/notice.png"/>  */}
                                        <Marquee loopData={this.state.quickRollNews} />
                                    </div>
                                    {/* 滚动文字样式请修改 marque.css marquee-vertical-txt*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OurShows />
                <AdsSlider />


                <div class="wrap wrap_gray pt20">
                    <div class="wrap wrap_gray">
                        <div class="container title">
                            <h1 class="title__h1 underscore">{this.props.t('label.breaking')}</h1>
                        </div>
                    </div>
                    <NewsList from="Home"/>
                </div>
            </section>
        );
    }
}

export default translate(Home);
